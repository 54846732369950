<template>
  <v-dialog content-class="article__modal" v-model="isOpen" max-width="1100" scrollable @keydown.esc="closeModal" @click:outside="closeModal" style="overflow: auto; z-index: 1500;">
    <v-card class="pt-md-6 pl-md-10 pr-md-13 pa-4 pt-8" v-if="!_.isEmpty(mutableArticle)">
      <v-btn class="close-btn icon-with-hover" @click="closeModal">
        <v-icon class="close-modal-icon">fa-times</v-icon>
      </v-btn>
      <div class="article__modal-header" v-if="!isMobile" :key="mutableArticle.id">
        <p class="article__modal-highlight" v-if="mutableArticle.is_highlight">{{ $t('Highlight') }}</p>
        <v-tooltip top allow-overflow>
          <template #activator="{ on, attrs }">
            <svg
              v-bind="attrs"
              v-on="on"
              height="30px"
              width="30px"
              :fill="globalTheme === ThemeType.dark ? '#ffffff' : '#000000'"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 101"
              viewBox="0 0 100 100" x="0px" y="0px"
              class="article__modal-shipping icon-with-hover mr-2"
              v-if="mutableArticle.no_shipping"
            >
              <path d="M50,90A40,40,0,1,0,10,50,40,40,0,0,0,50,90Zm0-73.23a33.1,33.1,0,0,1,21,7.46l-4.39,4.39a.63.63,0,0,1,.16.26L69,35.76,75.77,29A33.23,33.23,0,0,1,29,75.77l4.13-4.13h-2.4a.76.76,0,0,1-.75-.75V65.21L24.23,71A33.23,33.23,0,0,1,50,16.77Z" /><path d="M68.5,70.14V39.56H54v8.87a.76.76,0,0,1-.75.75H46.79a.76.76,0,0,1-.75-.75V39.56H31.5V70.14Z" /><polygon points="31.79 38.05 46.08 38.05 46.53 29.86 34.5 29.86 31.79 38.05" /><polygon points="53.92 38.05 68.21 38.05 65.5 29.86 53.47 29.86 53.92 38.05" /><polygon points="52.42 38.05 51.96 29.86 48.04 29.86 47.58 38.05 52.42 38.05" /><rect x="47.54" y="39.56" width="4.92" height="8.13" />
            </svg>
          </template>
          <span>{{ $t('No shipping possible') }}</span>
        </v-tooltip>
        <v-tooltip top allow-overflow>
          <template #activator="{ on, attrs }" v-if="enableArticleInqirires">
            <div
              v-bind="attrs"
              v-on="on"
              class="article__modal-inquiry pl-3 icon-with-hover mr-2"
              @click="openQuestionModal"
              v-if="isAuthenticatedAsUser"
            >
              <v-icon>fa-question-circle</v-icon>
            </div>
          </template>
          <span>{{ $t('Lot inquiry') }}</span>
        </v-tooltip>
        <v-tooltip top allow-overflow :key="wishlistKey">
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="article__modal-wish icon-with-hover"
              :class="{ active: mutableArticle.wishlist }"
              @click="addToWishList(mutableArticle)"
              :disabled="wishlistError"
              v-if="isAuthenticatedAsUser"
            >
              <v-icon :style="{ fontWeight: mutableArticle.wishlist ? 900 : 400 }">fa-heart</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('My Wishlist') }}</span>
        </v-tooltip>
      </div>
      <div class="article__modal-body">
        <div class="catalogue__show-image" v-show="!isMobile && mutableArticle.offensive && !showImages[`article${mutableArticle.id}`]">
          <div class="info-text" @click.stop="showImageToggle(mutableArticle.id)" v-if="isAuthenticatedAsUser">
            <v-icon>fa-info-circle</v-icon>
            <p>
              {{ $t('offensive_article_button_show_image') }}<br>
              <a :href="appSettings.url_rules" target="_blank">{{ $t('offensive_article_button_show_image2') }}</a>
            </p>
          </div>
          <v-btn class="btn  old-styling-btn" @click.stop="openLogin" v-else>{{ $t('Login to see images') }}</v-btn>
        </div>
        <div
          class="catalogue__image"
          v-if="!isMobile && mutableArticle.images && mutableArticle.images.length && mutableArticle.images.length <= 1"
          v-show="showImages[`article${mutableArticle.id}`] || !mutableArticle.offensive"
          @click="e => selectImage(e, mutableArticle.images, 0)"
        >
          <img :src="article.images[0].image">
        </div>
        <VueSlickCarousel lazyLoad="ondemand" :arrows="true" :key="mutableArticle.images.length" :dots="false" class="catalogue__slider" v-else-if="!isMobile && mutableArticle.images && mutableArticle.images.length > 1" v-show="showImages[`article${mutableArticle.id}`] || !mutableArticle.offensive">
          <template #prevArrow>
            <div class="custom-arrow">
              <v-icon class="arrow-left">fa-angle-left</v-icon>
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-arrow">
              <v-icon class="arrow-right">fa-angle-right</v-icon>
            </div>
          </template>
          <div
            class="image-item"
            v-for="(itm, idx) in mutableArticle.images"
            :key="itm.articleNumber"
            @click="e => selectImage(e, mutableArticle.images, idx)"
          >
            <img class="article-image" :src="itm.image" :style="{'max-height': isMobile ? '114px' : '156px'}">
          </div>
        </VueSlickCarousel>
        <div class="article__modal-content" :style="{ width: mutableArticle.images && mutableArticle.images.length ? '' : '100%' }">
          <h3 class="catalogue__name">{{ articleTitle(mutableArticle) }}</h3>
          <v-row justify="space-between" align="center">
            <v-col cols="12">
              <div class="catalogue__meta">
                <div class="catalogue__meta-item">
                  <p class="catalogue__meta-name">{{ $t('Article') }}</p>
                  <h5 class="catalogue__meta-value">#{{ mutableArticle.number_optional || mutableArticle.number }}</h5>
                </div>
                <div class="catalogue__meta-item" v-if="mutableArticle.limit && !selectedAuctionIsSale">
                  <p class="catalogue__meta-name" v-if="selectedAuctionData.type === AuctionFlavor.timed">{{ $t('Start price') }}</p>
                  <p class="catalogue__meta-name" v-else>{{ $t('Limit') }}</p>
                  <h5 class="catalogue__meta-value">{{ currency }} {{ euNumbers(mutableArticle.limit) }}</h5>
                </div>
                <div class="catalogue__meta-item" v-else-if="mutableArticle.buy_price && selectedAuctionIsSale">
                  <p class="catalogue__meta-name">{{ $t('Buy price') }}</p>
                  <h5 class="catalogue__meta-value">{{ currency }} {{ euNumbers(mutableArticle.buy_price) }}</h5>
                </div>
                <div class="catalogue__meta-item" v-if="selectedAuctionData.type !== AuctionFlavor.timed && !selectedAuctionIsSale && mutableArticle.estimated_price && (selectedAuctionData.catalogue_enabled === 'enabled' || selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations')">
                  <p class="catalogue__meta-name">{{ $t('Estimated price') }}</p>
                  <h5 class="catalogue__meta-value">{{ currency }} {{ mutableArticle.estimated_price }}</h5>
                </div>
                <div class="catalogue__meta-item" v-if="selectedAuctionData.type === AuctionFlavor.timed && mutableArticle.timedData">
                  <p class="catalogue__meta-name">{{ $t('Current price') }}</p>
                  <h5 class="catalogue__meta-value" v-if="mutableArticle.timedData.current_hammer_price">{{ currency }} {{ euNumbers(mutableArticle.timedData.current_hammer_price) }}</h5>
                  <h5 class="catalogue__meta-value" v-else>-</h5>
                </div>
                <!--                <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && mutableArticle.timedData && mutableArticle.timedData.active_from">-->
                <!--                  <p class="catalogue__meta-name" v-translate>Starts at</p>-->
                <!--                  <h5 class="catalogue__meta-value">{{ mutableArticle.timedData && getFormattedDate(mutableArticle.timedData.active_from, appStoreLang, true) }}</h5>-->
                <!--                </div>-->
                <div class="catalogue__meta-item" v-if="selectedAuctionData.type === AuctionFlavor.timed && mutableArticle.timedData && mutableArticle.timedData.active_until">
                  <p class="catalogue__meta-name">{{ $t('Ends at') }}</p>
                  <h5 class="catalogue__meta-value">{{ mutableArticle.timedData && getFormattedDate(mutableArticle.timedData.active_until, appStoreLang, true, false) }}</h5>
                </div>
                <div class="catalogue__meta-item" v-if="selectedAuctionData.type === AuctionFlavor.timed && mutableArticle.timedData && mutableArticle.timedData.active_until && getRemainingTime(mutableArticle.timedData.active_until, redrawRemainingTime)">
                  <p class="catalogue__meta-name">{{ $t('Remaining time') }}</p>
                  <h5 class="catalogue__meta-value">{{ getRemainingTime(mutableArticle.timedData.active_until, redrawRemainingTime) }}</h5>
                </div>
                <div class="catalogue__meta-item" v-if="selectedAuctionData.type === AuctionFlavor.live && selectedAuctionData.status !== AuctionStatusType.preparing && (selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations' || selectedAuctionData.catalogue_enabled === 'enabled_with_results')">
                  <p class="catalogue__meta-name">{{ $t('Knockdown') }}</p>
                  <h5 class="catalogue__meta-value" v-if="mutableArticle.sold_price">{{ currency }} {{ euNumbers(mutableArticle.sold_price) }}</h5>
                  <h5 class="catalogue__meta-value" v-else>-</h5>
                </div>
                <v-row class="ma-0 justify-md-end justify-start" v-if="!isMobile">
                  <v-btn
                    class="btn article__modal-shopping mb-3 mb-sm-0 old-styling-btn"
                    @click="openPostAuctionSaleModal"
                    v-if="isAuthenticatedAsUser && displayPostAuctionBtn && !mutableArticle.sold_price && (mutableArticle.status === '3' || mutableArticle.status === '4')"
                  >
                    <v-icon class="mr-2">fa-shopping-cart</v-icon><span>{{ $t('Post auction sale') }}</span>
                  </v-btn>
                  <v-col class="pl-0 flex-grow-0 text-md-right" v-if="isAuthenticatedAsUser && (selectedAuctionIsSale || selectedAuctionData.type === AuctionFlavor.timed)">
                    <v-btn
                      @click="buyArticle"
                      class="btn success old-styling-btn"
                      v-if="(mutableArticle.status === '2' || (selectedAuctionData.status === AuctionStatusType.started && selectedAuctionIsSale)) && mutableArticle.buy_price && mutableArticle.instant_buy_possible"
                    >
                      <v-icon small class="mr-2">fa-shopping-cart</v-icon>
                      <span>{{ $t('Buy now') }}</span>
                    </v-btn>
                    <p
                      class="mt-2 mb-0 text-no-wrap"
                      v-if="mutableArticle.status === '2' && !timedError && mutableArticle.timedData"
                      readonly
                    >
                      <span class="green--text" v-if="mutableArticle.timedData.bidder_status === 'highest-bidder'">{{ $t('You are the highest bidder') }}</span>
                      <span class="red--text" v-if="mutableArticle.timedData.bidder_status === 'outbid'">{{ $t('You are outbid, bid again') }}</span>
                    </p>
                    <v-btn
                      class="btn small mt-2 old-styling-btn"
                      @click="openTimedBidModal"
                      v-if="mutableArticle.status === '2' && selectedAuctionData.status === AuctionStatusType.started && !timedError && mutableArticle.timedData"
                    >
                      <v-icon small class="mr-2">fa-hammer</v-icon>
                      <span v-if="mutableArticle.timedData.bidder_status === 'no-bid'">{{ $t('Place bid') }}</span>
                      <span v-else>{{ $t('Place/Check bid') }}</span>
                    </v-btn>
                    <p
                      class="mt-2 text-no-wrap"
                      v-else-if="mutableArticle.status === '0' && !timedError"
                      readonly
                    >
                      <span class="green--text" v-if="mutableArticle.timedData.bidder_status === 'bought'">{{ $t('You have bought the article') }}</span>
                      <span class="green--text" v-if="mutableArticle.timedData.bidder_status === 'highest-bidder'">{{ $t('You have auctioned the article') }}</span>
                      <span class="red--text" v-if="mutableArticle.timedData.bidder_status === 'outbid'">{{ $t('Article sold') }}</span>
                    </p>
                  </v-col>
                  <div class="pa-3" v-if="isAuthenticatedAsUser && selectedAuctionData.commission_bids_enabled && selectedAuctionData.type === AuctionFlavor.live && selectedAuctionData.status !== AuctionStatusType.completed && ((!getCommissionBidsLength(article) && article.status == 1) || getCommissionBidsLength(article))">
                    <v-btn
                      class="btn article__modal-shopping old-styling-btn"
                      @click="openBidModal(false)"
                      v-if="!getCommissionBidsLength(mutableArticle) && mutableArticle.status === '1'"
                      :disabled="commissionError"
                      key="action-btn"
                    >
                      <span>{{ $t('+ Submit absentee bid') }}</span>
                    </v-btn>
                    <v-tooltip left v-if="getCommissionBidsLength(mutableArticle)">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on">
                          <v-btn class="btn ml-md-2 ml-0 old-styling-btn bid-status" :class="mutableArticle.commission_bids[0].status" disabled key="status-btn">
                            <v-icon v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.pending" style="font-weight: 400;" class="mr-1">fa-hourglass</v-icon>
                            <span v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.pending">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                            <v-icon v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.accepted" class="green-color-success mr-1">fa-check</v-icon>
                            <span v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.accepted">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                            <v-icon v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.declined" class="red-color mr-1">fa-times</v-icon>
                            <span v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.declined">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                          </v-btn>
                        </div>
                      </template>
                      <span v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.pending">{{ $t('Absentee bid pending') }}</span>
                      <span v-else-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.accepted">{{ $t('Absentee bid accepted') }}</span>
                      <span v-else-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.declined">{{ $t('Absentee bid declined') }}</span>
                    </v-tooltip>
                  </div>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <div class="catalogue__show-image" v-if="isMobile && mutableArticle.offensive && !showImages[`article${mutableArticle.id}`]" ref="carousel">
            <div class="info-text" @click.stop="showImageToggle(mutableArticle.id)" v-if="isAuthenticatedAsUser">
              <v-icon>fa-info-circle</v-icon>
              <p>
                {{ $t('offensive_article_button_show_image') }}<br>
                <a :href="appSettings.url_rules" target="_blank">{{ $t('offensive_article_button_show_image2') }}</a>
              </p>
            </div>
            <v-btn class="btn old-styling-btn" @click.stop="openLogin" v-else>{{ $t('Login to see images') }}</v-btn>
          </div>
          <div
            class="catalogue__image"
            v-else-if="isMobile && mutableArticle.images && mutableArticle.images.length && mutableArticle.images.length <= 1"
            @click="e => selectImage(e, article.images, 0)"
          >
            <img :src="mutableArticle.images[0].image">
          </div>
          <VueSlickCarousel lazyLoad="ondemand" :arrows="true" :key="mutableArticle.images.length" :dots="false" class="catalogue__slider" v-else-if="isMobile && mutableArticle.images && mutableArticle.images.length > 1">
            <template #prevArrow>
              <div class="custom-arrow">
                <v-icon class="arrow-left">fa-angle-left</v-icon>
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-arrow">
                <v-icon class="arrow-right">fa-angle-right</v-icon>
              </div>
            </template>
            <div
              class="image-item"
              v-for="(itm, idx) in mutableArticle.images"
              :key="itm.articleNumber"
              @click="e => selectImage(e, mutableArticle.images, idx)"
            >
              <img class="article-image" :src="itm.image" :style="{'max-height': isMobile ? '114px' : '156px'}">
            </div>
          </VueSlickCarousel>
          <v-row class="pb-3 px-3 justify-start justify-md-end" v-if="!isMobile && mutableArticle.commission_bids && getCommissionBidsLength(mutableArticle) && selectedAuctionData.status !== AuctionStatusType.completed && mutableArticle.status === '1'">
            <a class="article__modal-change" @click="openBidModal(true)">{{ $t('Change bid') }}</a>
          </v-row>
          <p v-if="useHtml" class="catalogue__description" v-html="articleDescription(mutableArticle)"></p>
          <p v-else class="catalogue__description">{{ articleDescription(mutableArticle) }}</p>
        </div>
      </div>
      <div class="article__modal-arrows">
        <v-icon class="icon-with-hover" @click="goToPrevArticle" v-if="prevArticle">fa-angle-left</v-icon>
        <div class="pl-3" v-else />
        <div class="article__modal-header" v-if="isMobile">
          <v-btn
            class="btn article__modal-shopping mr-2 mb-sm-0 old-styling-btn"
            @click="openPostAuctionSaleModal"
            v-if="isAuthenticatedAsUser && displayPostAuctionBtn && !mutableArticle.sold_price && (mutableArticle.status === '3' || mutableArticle.status === '4')"
          >
            <v-icon class="mr-2">fa-shopping-cart</v-icon><span>{{ $t('Post auction sale') }}</span>
          </v-btn>
          <template v-if="isAuthenticatedAsUser && (selectedAuctionIsSale || selectedAuctionData.type === AuctionFlavor.timed)">
            <v-btn
              @click="buyArticle"
              class="btn success mr-2 old-styling-btn"
              v-if="(mutableArticle.status === '2' || (selectedAuctionData.status === AuctionStatusType.started && selectedAuctionIsSale)) && mutableArticle.buy_price && mutableArticle.instant_buy_possible"
            >
              <v-icon small class="mr-2">fa-shopping-cart</v-icon>
              <span>{{ $t('Buy now') }}</span>
            </v-btn>
            <v-btn
              class="btn small mr-2 old-styling-btn"
              @click="openTimedBidModal"
              v-if="mutableArticle.status === '2' && selectedAuctionData.status === AuctionStatusType.started && !timedError && mutableArticle.timedData"
            >
              <v-icon small class="mr-2">fa-hammer</v-icon>
              <span v-if="mutableArticle.timedData.bidder_status === 'no-bid'">{{ $t('Place bid') }}</span>
              <span v-else>{{ $t('Place/Check bid') }}</span>
            </v-btn>
            <p
              class="mb-0 ml-2 bidder-status"
              v-else-if="mutableArticle.status === '0'"
              readonly
            >
              <span class="green--text" v-if="mutableArticle.timedData.bidder_status === 'bought'">{{ $t('You have bought the article') }}</span>
              <span class="green--text" v-if="mutableArticle.timedData.bidder_status === 'highest-bidder'">{{ $t('You have auctioned the article') }}</span>
              <span class="red--text" v-if="mutableArticle.timedData.bidder_status === 'outbid'">{{ $t('Article sold') }}</span>
            </p>
          </template>
          <div class="pa-1 article__modal-absentee" v-if="isAuthenticatedAsUser && selectedAuctionData.commission_bids_enabled && selectedAuctionData.type === AuctionFlavor.live && selectedAuctionData.status !== AuctionStatusType.completed && ((!getCommissionBidsLength(mutableArticle) && mutableArticle.status === '1') || getCommissionBidsLength(mutableArticle))">
            <v-btn
              class="btn article__modal-shopping old-styling-btn"
              @click="openBidModal(false)"
              v-if="!getCommissionBidsLength(mutableArticle) && mutableArticle.status === '1'"
              :disabled="commissionError"
              key="action-btn"
            >
              <span>{{ $t('+ Submit absentee bid') }}</span>
            </v-btn>
            <v-btn class="btn ml-md-2 ml-0 old-styling-btn" :class="mutableArticle.commission_bids[0].status" v-if="getCommissionBidsLength(mutableArticle)" disabled key="status-btn">
              <v-icon v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.pending" style="font-weight: 400;" class="mr-1">fa-hourglass</v-icon>
              <span v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.pending">{{ $t('Absentee bid') }} {{ euNumbers(mutableArticle.commission_bids[0].commission_bid) }} {{ currency }}</span>
              <v-icon v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.accepted" class="green-color-success mr-1">fa-check</v-icon>
              <span v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.accepted">{{ $t('Absentee bid') }} {{ euNumbers(mutableArticle.commission_bids[0].commission_bid) }} {{ currency }}</span>
              <v-icon v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.declined" class="red-color mr-1">fa-times</v-icon>
              <span v-if="mutableArticle.commission_bids[0].status === CommissionBidStatusType.declined">{{ $t('Absentee bid') }} {{ euNumbers(mutableArticle.commission_bids[0].commission_bid) }} {{ currency }}</span>
            </v-btn>
            <a class="article__modal-change" @click="openBidModal(true)" v-if="mutableArticle.commission_bids && getCommissionBidsLength(mutableArticle) && selectedAuctionData.status !== AuctionStatusType.completed && mutableArticle.status === '1'">{{ $t('Change bid') }}</a>
          </div>
          <v-tooltip top allow-overflow>
            <template #activator="{ on, attrs }">
              <svg
                v-bind="attrs"
                v-on="on"
                height="30px"
                width="30px"
                :fill="globalTheme === ThemeType.dark ? '#ffffff' : '#000000'"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 101"
                viewBox="0 0 100 100" x="0px" y="0px"
                class="article__modal-shipping mr-2"
                v-if="mutableArticle.no_shipping"
              >
                <path d="M50,90A40,40,0,1,0,10,50,40,40,0,0,0,50,90Zm0-73.23a33.1,33.1,0,0,1,21,7.46l-4.39,4.39a.63.63,0,0,1,.16.26L69,35.76,75.77,29A33.23,33.23,0,0,1,29,75.77l4.13-4.13h-2.4a.76.76,0,0,1-.75-.75V65.21L24.23,71A33.23,33.23,0,0,1,50,16.77Z" /><path d="M68.5,70.14V39.56H54v8.87a.76.76,0,0,1-.75.75H46.79a.76.76,0,0,1-.75-.75V39.56H31.5V70.14Z" /><polygon points="31.79 38.05 46.08 38.05 46.53 29.86 34.5 29.86 31.79 38.05" /><polygon points="53.92 38.05 68.21 38.05 65.5 29.86 53.47 29.86 53.92 38.05" /><polygon points="52.42 38.05 51.96 29.86 48.04 29.86 47.58 38.05 52.42 38.05" /><rect x="47.54" y="39.56" width="4.92" height="8.13" />
              </svg>
            </template>
            <span>{{ $t('No shipping possible') }}</span>
          </v-tooltip>
          <v-tooltip top allow-overflow v-if="enableArticleInqirires">
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="article__modal-inquiry icon-with-hover pl-3 mr-2"
                @click="openQuestionModal"
                v-if="isAuthenticatedAsUser"
              >
                <v-icon>fa-question-circle</v-icon>
              </div>
            </template>
            <span>{{ $t('Lot inquiry') }}</span>
          </v-tooltip>
          <v-tooltip top allow-overflow :key="wishlistKey">
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                class="article__modal-wish icon-with-hover"
                :class="{ active: mutableArticle.wishlist }"
                @click="addToWishList(mutableArticle)"
                :disabled="wishlistError"
                v-if="isAuthenticatedAsUser"
              >
                <v-icon :style="{ fontWeight: mutableArticle.wishlist ? 900 : 400 }">fa-heart</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('My Wishlist') }}</span>
          </v-tooltip>
        </div>
        <v-icon class="icon-with-hover" @click="goToNextArticle" v-if="nextArticle">fa-angle-right</v-icon>
      </div>
    </v-card>
    <CoolLightBox
      :items="selectedImages"
      :index="lightBoxIndex"
      :useZoomBar="true"
      :disableZoom=disableZoomOnMobile
      :closeOnClickOutsideMobile="true"
      @on-open="isLightBoxOpen = true"
      @close="lightBoxIndex = null; isLightBoxOpen = false"
    />
    <user-article-inquiry-modal
      ref="inquiryModal"
      :article="mutableArticle"
    />
    <post-auction-sale-modal
      :post-auction-sale-modal="postAuctionSaleModal"
      :article="mutableArticle"
      @closePostAuctionSaleModal="closePostAuctionSaleModal"
    />
    <confirmation-modal
      :title="`${$t('Confirm buy request')}`"
      :text="`${$t('Do you really want to buy the article for')} ${appLocalization.currency} ${euNumbers(mutableArticle.buy_price)}`"
      @submit="submitBuy"
      @cancel="cancelBuy"
      ref="confirmationModal"
      v-if="!_.isEmpty(mutableArticle)"
    />
    <instant-buy-modal
      ref="instantBuyModal"
      :loading="instantBuyLoading"
      @submitBuyArticle="submitBuyArticle"
    />
    <timed-bid-modal
      :auction="selectedAuctionData"
      :article="mutableArticle"
      @updateArticle="updateArticle"
      ref="timedBidModal"
    />
    <change-bid-modal
      :commissionBidDialog="bidDialog"
      :editBid="editBid"
      :loading="loading"
      :selectedArticle="mutableArticle"
      :selectedAuctionData="selectedAuctionData"
      update-data
      @setLoading="e => loading = e"
      @closeBidModal="closeBidModal"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { localFeatures } from '@/utils/constants'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import UserArticleInquiryModal from '@/components/modals/user-article-inquiry-modal'
import ConfirmationModal from '@/components/modals/confirmation-modal'
import InstantBuyModal from '@/components/modals/user-request-instant-buy'
import TimedBidModal from '@/components/modals/user-timed-bid-modal'
import ChangeBidModal from '@/components/modals/user-commission-bid-modal'
import PostAuctionSaleModal from '@/components/modals/user-post-auction-sale-modal.vue';
import counter from '@/mixins/counter'
import dispatcher from '@/api/dispatch';
import tinykeys from '@/utils/tiny-keys';
import useRootStore from '@/stores/rootStore';
import GlobalEvents, { GlobalEventType } from '@/utils/globalEvents';
import { euNumbers, getFormattedDate, getTranslatedText } from '@/services/i18n';
import { getImageFullUrl, getImageOrFallback } from '@/services/auction';
import { AlertFlavor, CommissionBidStatusType, AuctionFlavor, AuctionStatusType, ThemeType } from '@/api/enums';
import useAuctionStore from '@/stores/auctionStore';

export default {
  name: 'ArticleDetailsModal',
  components: {
    VueSlickCarousel,
    CoolLightBox,
    UserArticleInquiryModal,
    ConfirmationModal,
    InstantBuyModal,
    TimedBidModal,
    ChangeBidModal,
    PostAuctionSaleModal
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    prevArticle: {
      type: Object,
      default: null
    },
    nextArticle: {
      type: Object,
      default: null
    },
    selectedAuctionData: {
      type: Object,
      default: null
    },
    wishlistError: {
      type: Boolean,
      default: false
    },
    timedError: {
      type: Boolean,
      default: false
    },
    commissionError: {
      type: Boolean,
      default: false
    },
    clearArticleNumber: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      lightBoxIndex: null,
      selectedImages: [],
      loading: false,
      disableButton: true,
      postAuctionSaleModal: false,
      instantBuyLoading: false,
      editBid: false,
      bidDialog: false,
      bid: null,
      wishlistKey: 0,
      showImages: {},
      isLightBoxOpen: false,
      removeListener: null,
      mutableArticle: this.article,
      useHtml: localFeatures.useHtml,
      enableArticleInqirires: localFeatures.enableArticleInqirires
    }
  },
  mixins: [
    counter
  ],
  computed: {
    AuctionStatusType() {
      return AuctionStatusType
    },
    AuctionFlavor() {
      return AuctionFlavor
    },
    CommissionBidStatusType() {
      return CommissionBidStatusType
    },
    ThemeType() {
      return ThemeType
    },
    ...mapState(useRootStore, ['isMobile', 'appLocalization', 'appStoreLang', 'appSettings', 'userProfile', 'appFeatures', 'isAuthenticatedAsUser', 'globalTheme']),
    currency() {
      return this.appLocalization.currency
    },
    displayPostAuctionBtn() {
      return this.selectedAuctionData && (this.selectedAuctionData.catalogue_enabled === 'enabled_with_results' || this.selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations') && this.selectedAuctionData.post_auction_sale_enabled && this.selectedAuctionData.status !== AuctionStatusType.preparing && this.selectedAuctionData.type === AuctionFlavor.live
    },
    selectedAuctionIsSale() {
      return this.selectedAuctionData && this.selectedAuctionData.type === AuctionFlavor.sale
    },
    disableZoomOnMobile() {
      return !!this.isMobile
    }
  },
  watch: {
    // evaluate every time the modal is opened
    isOpen() {
      if (this.isOpen) {
        this.removeListener = tinykeys(window, {
          ArrowLeft: () => {
            if (!this.isMobile && this.prevArticle && !this.isLightBoxOpen) this.goToPrevArticle()
          },
          ArrowRight: () => {
            if (!this.isMobile && this.nextArticle && !this.isLightBoxOpen) this.goToNextArticle()
          },
        });
        GlobalEvents.subscribeEvent(GlobalEventType.updateArticle, this.handleAuctionDataUpdate)
      } else {
        this.removeListener()
        GlobalEvents.unsubscribeEvent(GlobalEventType.updateArticle, this.handleAuctionDataUpdate)
      }
    },
    article(val) {
      this.mutableArticle = val;
    }
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    handleAuctionDataUpdate() {
      const auctionStore = useAuctionStore();
      const articles = auctionStore.auctionsFetchedArticles[`auction_${this.selectedAuctionData.id}`]
      //console.log('new values ' + JSON.stringify(articles))
      if (!articles) return
      const filteredArticles = articles.filter(e => e.id === this.mutableArticle.id);
      if (filteredArticles.length > 0) {
        this.mutableArticle = filteredArticles[0];
      }
    },
    getFormattedDate,
    euNumbers,
    openModal() {
      this.isOpen = true
    },
    closeModal() {
      this.$emit('closeArticleDetailedModal')
      this.isOpen = false
      // if (this.$route.name !== 'dashboard') this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id } })
    },
    getCommissionBidsLength(article) {
      return article.commission_bids?.length
    },
    articleTitle(article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription(article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    openQuestionModal() {
      this.$refs.inquiryModal.open()
    },
    async addToWishList(article) {
      if (article.wishlist) {
        try {
          await dispatcher.removeFromWishlist(this.selectedAuctionData.id, this.userProfile.id, article.id)
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Article has been removed from your wishlist'), timeout: 1000 })
          article.wishlist = false
          this.wishlistKey++
        } catch (e) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error removing that article from the wishlist. Please try again later.') })
        }
      } else {
        try {
          await dispatcher.addToWishlist(this.selectedAuctionData.id, this.userProfile.id, article.id)
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Article has been placed on your wishlist'), timeout: 1000 })
          article.wishlist = true
          this.wishlistKey++
        } catch (e) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error adding that article to the wishlist. Please try again later.') })
        }
      }
    },
    async selectImage(e, images, index) {
      if (e.target.classList.contains('image-item')) {
        e.target.blur()
      } else {
        e.target.parentNode.blur()
      }
      let arr = []
      for await (let image of images) {
        const res = await getImageOrFallback(getImageFullUrl(image.image, this.selectedAuctionData.code), image.image)
        arr.push(res)
      }
      this.selectedImages = arr
      this.lightBoxIndex = index
    },
    openPostAuctionSaleModal(article) {
      this.selectedArticle = article
      this.postAuctionSaleModal = true
    },
    closePostAuctionSaleModal() {
      this.selectedArticle = {}
      this.postAuctionSaleModal = false
    },
    buyArticle() {
      if (this.selectedAuctionIsSale) {
        this.$refs.instantBuyModal.openModal(`${this.currency} ${euNumbers(this.mutableArticle.buy_price)}`)
      } else {
        this.$refs.confirmationModal.openModal()
      }
    },
    async submitBuy() {
      try {
        const result = await dispatcher.instantBuy(this.selectedAuctionData.id, this.mutableArticle.id)
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('You have successfully bought the article') })
        this.mutableArticle = { ...result }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error buying the article. Please try again later') })
      } finally {
        this.$refs.confirmationModal.closeModal()
      }
    },
    cancelBuy() {
      this.$refs.confirmationModal.closeModal()
    },
    async submitBuyArticle() {
      try {
        this.instantBuyLoading = true
        const data = {
          type: 'instant-buy',
          lang: this.appStoreLang,
          article_id: this.mutableArticle.id
        }
        await dispatcher.sendEmailInquiry(this.userProfile.id, data)
        this.instantBuyLoading = false
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('We have received your request and will get back to you as soon as possible') })
        this.$refs.instantBuyModal.closeModal()
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error sending the inquiry. Please try again later') })
        this.instantBuyLoading = false
      }
    },
    openTimedBidModal() {
      this.$refs.timedBidModal.openModal()
    },
    updateArticle(data) {
      this.mutableArticle.timedBidData = data
    },
    openBidModal(editMode = false) {
      this.bidDialog = true
      this.editBid = editMode
      this.pickUp = this.selectedAuctionData.no_shipping
    },
    closeBidModal() {
      this.bidDialog = false
    },
    goToPrevArticle() {
      this.$emit('openArticleDetailedModal', this.prevArticle)
    },
    goToNextArticle() {
      this.$emit('openArticleDetailedModal', this.nextArticle)
    },
    showImageToggle(id) {
      this.showImages = {
        ...this.showImages,
        [`article${id}`]: true
      }
    },
    openLogin() {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style scoped lang="scss">
.close-btn-wrapper {
  position: relative;
  top: 0 !important;
}
.article__modal {
  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: -10px -10px 0 0;
    @media (max-width: 968px) {
      margin: 0;
      .bidder-status {
        font-size: 12px;
        max-width: 50%;
      }
    }
  }
  &-highlight {
    position: absolute;
    left: 0;
    margin: 0 8px 0 0;
    background: #FFD700;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    padding: 5px;
    top: 17px;
  }
  &-inquiry {
    .v-icon {
      font-size: 24px;
    }
  }
  &-buy.v-btn {
    background-color: green !important;
    &.small {
      display: block;
      cursor: pointer;
      width: 100%;
      max-width: 240px;
      font-size: 10px;
      padding: 0 6px !important;
      height: 28px !important;
      i {
        font-size: 14px;
      }
    }
  }
  &-wish {
    cursor: pointer;
    &.active {
      i {
        color: #8b141e !important;
        transition: all .3s ease;
      }
    }
  }
  &-inquiry,
  &-bids {
    cursor: pointer;
    &.active {
      i {
        color: #8b141e !important;
        transition: all .3s ease;
      }
    }
    .v-icon {
      font-size: 24px;
    }
  }
  &-absentee {
    @media (max-width: 968px) {
      max-width: 180px;
      position: relative;
      margin-right: 8px;
    }
  }
  &-change {
    font-weight: 500;
    margin: 5px 0;
    font-size: 12px;
    @media (max-width: 968px) {
      position: absolute;
      left: 4px;
      top: 32px;
    }
  }
  &-status,
  &-shopping {
    margin-top: 10px;
    display: block;
    cursor: pointer;
    width: 100%;
    max-width: 240px;
    @media (max-width: 968px) {
      margin-top: 0;
    }
    &.small {
      font-size: 10px;
      padding: 0 6px !important;
      height: 28px !important;
      i {
        font-size: 14px;
      }
    }
    i {
      font-size: 21px;
    }
    &.active {
      i {
        color: #8b141e !important;
        transition: all .3s ease;
      }
    }
  }
  &-body {
    width: 100%;
    max-width: 100%;
    display: flex;
  }
  &-content {
    width: calc(75% - 25px);
    @media (max-width: 968px) {
      width: 100%;
    }
  }
  &-change {
    font-size: 14px;
    @media (max-width: 968px) {
      font-size: 12px;
    }
  }
  &-shipping {
    cursor: pointer;
  }
  &-arrows {
    margin-top: 10px;
    padding-bottom: 10px;
    margin-left: -32px;
    margin-right: -44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 968px) {
      margin-top: 15px;
      padding-top: 5px;
      border-top: 1px solid #262626;
      margin-left: -8px;
      margin-right: -8px;
      padding-bottom: 5px;
    }
    .v-icon {
      font-size: 24px;
    }
  }
  .btn {
    @media (max-width: 968px) {
      height: 30px !important;
      padding: 0 8px !important;
      font-size: 10px;
      width: auto;
      .v-icon {
        font-size: 16px;
      }
    }
  }
}
.catalogue {
  &__meta {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @media (max-width: 968px) {
      flex-direction: row;
    }
  }
  &__meta-item {
    margin: 5px 0px 5px 8px;
    padding-left: 8px;
    border-left: 1px solid #E5E5E5;
    @media (max-width: 968px) {
      display: flex;
      border: none;
      padding-left: 0;
      margin-left: 0;
    }
    &:last-child, &:first-child {
      border-left: none;
      padding-left: 0;
      margin-left: 0;
    }
  }
  &__meta-name {
    margin: 0 0 0 0 !important;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    opacity: .6;
    @media (max-width: 968px) {
      margin-right: 3px !important;
    }
  }
  &__meta-value {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  &__name {
    font-weight: 600;
    margin-top: 0;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    -webkit-line-clamp: 3;
  }
  &__description {
    margin: 10px 0 0;
    font-size: 12px;
    line-height: 18px;
    opacity: .8;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    -webkit-line-clamp: unset;
    @media (max-width: 968px) {
      font-size: 14px;
      opacity: 1;
      max-height: calc(100vh - 505px);
      overflow: auto;
      margin-bottom: 10px !important;
    }
  }
  &__image,
  &__show-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 156px;
    img {
      display: block;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
  &__slider,
  &__image,
  &__show-image {
    cursor: zoom-in;
    min-width: 25%;
    width: 25%;
    margin-right: 25px;
    @media (max-width: 968px) {
      margin-top: 10px;
      min-width: 100%;
      width: 100%;
      margin-right: 0;
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
    }
  }
  &__show-image {
    cursor: default;
  }
  &__slider {
    height: 156px;
    .image-item {
      @media (max-width: 968px) {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.slick-next {
  @media (max-width: 968px) {
    right: 0;
    &:before {
      display: none;
    }
    .v-icon {
      color: rgba(0, 0, 0, .7);
    }
  }
}
.slick-prev {
  @media (max-width: 968px) {
    left: 0;
    text-align: right;
    &:before {
      display: none;
    }
    .v-icon {
      color: rgba(0, 0, 0, .7);
    }
  }
}
</style>
